<template>
  <a-layout class="layout">
    <a-layout-header :style="{ padding: '0 25px' }">
      <MenuLogo />
      <div
        v-if="$store.state.account.loginStatus"
        class="setting-button"
        @click="$router.push('/menu')"
        @keydown="$router.push('/menu')"
      >
        <MenuOutlined :style="{ fontSize: '20px' }" />
      </div>
    </a-layout-header>
    <div v-if="status.isLoading" class="loading">
      <a-spin :indicator="indicator" />
    </div>
    <router-view v-else />
  </a-layout>
</template>

<script>
import {
  defineComponent, getCurrentInstance, h, reactive,
} from 'vue';
import MenuLogo from '@/components/project/MenuLogo.vue';
import { MenuOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { checkAuthToken, checkUserExist } from '@/network/http';
import { useCookie } from 'vue-cookie-next';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    MenuLogo,
    MenuOutlined,
  },
  setup() {
    const currentInstance = getCurrentInstance();
    const globalFunctions = currentInstance.appContext.config.globalProperties.$globalFunctions;
    const cookie = useCookie();
    const store = useStore();
    const router = useRouter();
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
    const status = reactive({
      isLoading: true,
    });
    if (cookie.isCookieAvailable('token')) {
      const token = cookie.getCookie('token');
      checkAuthToken(
        globalFunctions.generateRequestHeader(token),
      ).then((response) => {
        globalFunctions.handleAPIResponse(
          response,
          () => {
            store.commit('account/setToken', token);
            store.commit('account/setLoginStatus', true);
            store.commit('account/setInitializeStatus', true);
            status.isLoading = false;
          },
          () => {
            cookie.removeCookie('token');
            store.commit('account/setLoginStatus', false);
            store.commit('account/setInitializeStatus', true);
            status.isLoading = false;
            router.push('/login');
          },
        );
      }).catch((error) => {
        globalFunctions.notify({ content: '请求失败' });
        // eslint-disable-next-line no-console
        console.log(error);
      });
    } else {
      checkUserExist().then((response) => {
        globalFunctions.handleAPIResponse(
          response,
          () => {
            store.commit('account/setLoginStatus', false);
            store.commit('account/setInitializeStatus', true);
            status.isLoading = false;
            router.push('/login');
          },
          () => {
            store.commit('account/setLoginStatus', false);
            store.commit('account/setInitializeStatus', true);
            status.isLoading = false;
            router.push('/register');
          },
        );
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });
    }
    return {
      indicator,
      status,
    };
  },
});
</script>

<style lang="scss">
@import "assets/styles/index";

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

[data-theme='dark'] .site-layout-content {
  background: #141414;
}

.setting-button {
  color: white;
  float: right;
  line-height: initial;
  margin-top: 22px;
  cursor: pointer;
}
</style>
