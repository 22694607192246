export default class CamelUnderlineConversion {
  #arr = [];

  #obj;

  constructor(obj) {
    this.#obj = obj;
  }

  #stringCamelToUnderline(str) {
    this.#arr = [];
    const UP_CASE_REG = /[A-Z]/g;
    const NUMBER_REG = /[A-Za-z][\d]/g;
    let newStr = '';
    this.#getIndex(UP_CASE_REG, str);
    this.#getIndex(NUMBER_REG, str);
    this.#arr.sort((a, b) => a - b);
    for (let i = 0; i < this.#arr.length; i += 1) {
      if (i === 0) {
        newStr += `${str.slice(0, this.#arr[i])}_`;
      } else {
        newStr += `${str.slice(this.#arr[i - 1], this.#arr[i])}_`;
      }
    }
    newStr += str.slice(this.#arr[this.#arr.length - 1]);
    return newStr.toLowerCase();
  }

  #getIndex(reg, str) {
    do {
      reg.test(str);
      if (reg.lastIndex !== 0 && reg.lastIndex - 1 !== 0) { // reg.lastIndex-1 !== 0判断首字母是否大写
        this.#arr.push(reg.lastIndex - 1);
      }
    } while (reg.lastIndex > 0);
  }

  #camelToUnderlineExecute(obj) {
    if (typeof obj === 'object') {
      let newObj;
      if (Array.isArray(obj)) {
        newObj = [];
        obj.forEach((value) => {
          newObj.push(this.#camelToUnderlineExecute(value));
        });
      } else {
        newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          newObj[this.#stringCamelToUnderline(key)] = this.#camelToUnderlineExecute(value);
        });
      }
      return newObj;
    }
    return obj;
  }

  camelToUnderline() {
    return this.#camelToUnderlineExecute(this.#obj);
  }

  static #stringUnderlineToCamel(str) {
    return str.replace(/_(\w)/g, (all, letter) => letter.toUpperCase());
  }

  #underlineToCamelExecute(obj) {
    if (typeof obj === 'object') {
      let newObj;
      if (Array.isArray(obj)) {
        newObj = [];
        obj.forEach((value) => {
          newObj.push(this.#underlineToCamelExecute(value));
        });
      } else {
        newObj = {};
        Object.entries(obj).forEach(([key, value]) => {
          newObj[
            CamelUnderlineConversion.#stringUnderlineToCamel(key)
          ] = this.#underlineToCamelExecute(value);
        });
      }
      return newObj;
    }
    return obj;
  }

  underlineToCamel() {
    return this.#underlineToCamelExecute(this.#obj);
  }
}
