const IS_DEV = false;

export const API_URL = IS_DEV
  ? 'http://api.test.intelligent-farm.palpitation.moe'
  : 'https://api.intelligent-farm.palpitation.moe';
export const WEBSOCKET_API_URL = IS_DEV
  ? 'ws://api.test.intelligent-farm.palpitation.moe'
  : 'wsd://api.intelligent-farm.palpitation.moe';

export const ERROR_CODE_DEFINITION = {};

export const MESSAGE_CONFIG = {
  top: '10px',
  duration: 2,
  maxCount: 3,
};
