import { ERROR_CODE_DEFINITION, MESSAGE_CONFIG } from '@/utilities/constants';
import { message } from 'ant-design-vue';
import CamelUnderlineConversion from '@/utilities/CamelUnderlineConversion';

export function notify(payload, type) {
  message.config(MESSAGE_CONFIG);
  switch (type) {
    case 'success':
      message.success(payload);
      break;
    case 'error':
      message.error(payload);
      break;
    case 'info':
      message.info(payload);
      break;
    case 'warning':
      message.warning(payload);
      break;
    default:
      message.info(payload);
  }
}

export function generateRequestHeader(token) {
  return { 'Auth-Token': token };
}

export function handleAPIResponse(rawResponse, success, failure) {
  const camelUnderlineConversion = new CamelUnderlineConversion(rawResponse);
  const response = camelUnderlineConversion.underlineToCamel();
  if (response.data.isSuccess === true) {
    if (response.data.content !== undefined) {
      success(response.data.content);
    } else {
      notify({ content: '未知错误' });
    }
  } else if (failure) {
    failure();
  } else {
    let notice;
    if (ERROR_CODE_DEFINITION[response.data.content]) {
      notice = ERROR_CODE_DEFINITION[response.data.content];
    } else {
      notice = response.data.content !== undefined
        ? response.data.content
        : '未知错误';
    }
    notify({ content: notice });
  }
}
