<template>
  <a-layout-content>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="定时任务"
    >
      <template #extra>
        <a-button
          type="primary"
          @click="$router.push('/task-setting')"
        >
          新建任务
        </a-button>
      </template>
    </a-page-header>
    <div v-if="status.isLoading" class="loading">
      <a-spin :indicator="indicator" />
    </div>
    <a-table
      v-else
      :columns="columns"
      :data-source="taskList"
      :scroll="{y: tableHeight}"
      :pagination="false"
    >
      <template #action="{ record }">
        <a-button
          shape="circle"
          @click="$router.push('/task-setting/' + record.key)"
        >
          <template #icon><SettingOutlined /></template>
        </a-button>
      </template>
    </a-table>
  </a-layout-content>
</template>

<script>
import {
  defineComponent, getCurrentInstance, onMounted, reactive, h,
} from 'vue';
import { LoadingOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { getTaskList } from '@/network/http';

const columns = [
  {
    title: '任务名称',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: '执行时间',
    key: 'time',
    dataIndex: 'time',
    width: 120,
  },
  {
    title: '操作',
    key: 'action',
    width: 66,
    slots: {
      customRender: 'action',
    },
  },
];

export default defineComponent({
  name: 'HomeView',
  components: {
    SettingOutlined,
  },
  setup() {
    const currentInstance = getCurrentInstance();
    const globalFunctions = currentInstance.appContext.config.globalProperties.$globalFunctions;
    const taskList = reactive([]);
    let tableHeight = `${document.documentElement.clientHeight - 138}px`;
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '24px',
      },
      spin: true,
    });
    const status = reactive({
      isLoading: true,
    });
    onMounted(() => {
      getTaskList(
        globalFunctions.generateRequestHeader('test'),
      ).then((response) => {
        globalFunctions.handleAPIResponse(
          response,
          (content) => {
            content.forEach((item) => {
              taskList.push({
                key: item.id,
                name: item.name,
                time: item.time,
              });
            });
            status.isLoading = false;
          },
        );
      }).catch((error) => {
        globalFunctions.notify({ content: '请求失败' });
        // eslint-disable-next-line no-console
        console.log(error);
      });
      window.onresize = () => {
        tableHeight = `${document.documentElement.clientHeight - 138}px`;
      };
    });
    return {
      columns,
      taskList,
      tableHeight,
      indicator,
      status,
    };
  },
});
</script>

<style lang="scss" scoped>
.ant-table-body {
  max-height: v-bind(tableHeight) !important;
}

.ant-table-body-inner {
  max-height: v-bind(tableHeight) !important;
}
</style>
